import React from "react";
import BackgroundImg from "gatsby-background-image";

import * as styles from "./PageTitle.module.css";

const PageTitle = (props) => {
  return (
    <BackgroundImg className={styles.pageTitle} fixed={props.image}>
      <div
        className={`flex justify-center items-center h-full md:w-3/4 w-full mx-auto ${styles.pageTitleContainer}`}
      >
        <div className={styles.pageTitleContent}>
          <div className="font-serif md:text-5xl text-4xl uppercase tracking-wider">
            {props.title}
          </div>
        </div>
      </div>
      <div className={styles.pageTitleOverlay}></div>
    </BackgroundImg>
  );
};

export default PageTitle;
