import React from "react";
import { Link } from "gatsby";
import BackgroundImg from "gatsby-background-image";

const TopicCard = (props) => {
  return (
    <div
      className="flex flex-col border shadow rounded h-full"
      style={{ borderColor: props.topic.color.hex }}
    >
      <Link to={"/topics/" + props.topic.slug.current}>
        {props.topic.mainImage ? (
          <BackgroundImg
            className="w-full"
            style={{ height: "300px" }}
            fluid={props.topic.mainImage.asset.fluid}
          />
        ) : (
          <div style={{ height: "300px" }} className="bg-gray-300 w-full"></div>
        )}

        <div className="p-6 break-words">
          <div className="flex justify-between">
            <h2 className="font-serif text-3xl leading-tight">
              {props.topic.name}
            </h2>
            <i className={`text-3xl ${props.topic.icon || "fas fa-ban"}`}></i>
          </div>
          <p className="mt-3">{props.topic.description}</p>
        </div>
      </Link>
    </div>
  );
};

export default TopicCard;
