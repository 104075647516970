import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import PageTitle from "../components/PageTitle";
import TopicCard from "../components/TopicCard";

const TopicsPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Topics"
        keywords={[`health`, `fitness`, `topics`, `health topics`]}
      />
      <PageTitle image={data.settings.headerImage.asset.fixed} title="Topics" />
      <div className="md:w-3/4 w-full mx-auto px-6 py-12">
        <div className="flex flex-row flex-wrap justify-left -mx-3">
          {data.topics.edges.map((topic, i) => (
            <div
              key={i}
              className="flex-grow-0 sm:w-1/2 w-full whitespace-normal px-3 mb-6"
            >
              <TopicCard topic={topic.node} />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TopicsPageQuery {
    settings: sanitySettings {
      headerImage: pageHeaderTopics {
        asset {
          fixed(width: 1920, height: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    topics: allSanityTopic {
      edges {
        node {
          icon
          color {
            hex
          }
          slug {
            current
          }
          name
          description
          mainImage {
            asset {
              fluid(maxHeight: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default TopicsPage;
